import React from 'react'
import HeroSection from '../../components/Home/HeroSection'
import AboutSection from '../../components/Home/AboutSection'
import MarqueeSection from '../../components/Home/MarqueeSection'
import Pricing from '../../components/Home/Pricing'
import Contactus from '../../components/Home/Contactus'
import Testimonial from '../../components/Home/Testimonial'
import GetAQuote from '../../components/GetAQuote'
import Scalling from '../../components/Home/Scalling'
import MobileApp from '../../components/Home/MobileApp'
import { Helmet } from 'react-helmet'
// import FAQ from '../../components/FAQ'

const Home = () => {
    return (
        <>
            <Helmet>
                <meta charset="utf-8" />
                <title></title>
                <meta name="description" content="" />
                <meta name="keywords" content="" />
            </Helmet>
            <div style={{ backgroundColor: 'white' }}>
                <HeroSection />
                <AboutSection />
                <Scalling />
                <MobileApp />
                <MarqueeSection />
                <Pricing />
                <Contactus />
                <Testimonial />
                <GetAQuote />
                {/* <FAQ /> */}
            </div>
        </>
    )
}

export default Home
