// eslint-disable-next-line
import { useContext, useState, useEffect } from 'react'
// eslint-disable-next-line
import { StepperContext } from '../contexts/StepperContext'
import React from 'react'
import { useNavigate } from 'react-router-dom';
import styles from './Content.module.css'
import ConfettiExplosion from 'react-confetti-explosion';


const Step19 = ({ currentStep, steps }) => {
    const navigate = useNavigate();
    // eslint-disable-next-line
    const [isExploding, setIsExploding] = useState(true);
    // const { userData, setUserData } = useContext(StepperContext);
    return (
        <div className={`${styles.StepOuterDiv} ${styles.Step19OuterDiv}`}>
            <div className={styles.topBar}>
                <div>
                    {isExploding && <ConfettiExplosion />}
                </div>
                <div className={styles.eyebrow__flashing}>
                    <div className={styles.eyebrow__flashing_dot}>
                    </div>
                    <h2>Congratulations! We're Looking Forward To Speaking With you...</h2>
                </div>
                <h1>Important! Please Watch This Short </h1>
                <h1>Video Before Your Call</h1>
                <p>
                    (It's incredibly important that you watch this entire video before your call).
                </p>
                {/* <button onClick={() => setIsExploding(true)}>
                    click
                </button> */}
                <div className={styles.VideoDiv}>
                    <iframe className={styles.video} title='video' src="https://streamable.com/e/v5jhg1?autoplay=1" frameborder="0" allowfullscreen allow="autoplay" >
                    </iframe></div>

            </div>
            <div className={styles.ButtonDiv}>

                <button className={styles.btn} id={styles.btn1} onClick={() => navigate('/')} >
                    <div>
                        Back To Murphys
                    </div>
                </button>
            </div>
        </div>
    )
}

export default Step19;