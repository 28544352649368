import React from "react";
// import TopBanner from '../../components/TopBanner/TopBanner'
import styles from "./About.module.css";
import image12 from "../../img/About/Component-25.webp";
import curves from "../../img/Flaticon/curvedAarrow.webp";
import Vision from "../../img/About/Component-23.webp";
import image2 from "../../img/About/aboutimage2.webp";
import image3 from "../../img/About/Component 24.webp";
import { useNavigate } from "react-router";
import { motion } from "framer-motion";
import GetAQuote from "../../components/GetAQuote";
import ScrollAnimation from "../../components/Animation/ScrollAnimation";
import ImageAnimation from "../../components/Animation/ImageAnimation";
import TopBanner2 from "../../components/TopBanner/TopBanner2";
import { Helmet } from "react-helmet";
const About = () => {
  // eslint-disable-next-line
  const navigate = useNavigate();
  const hrVariants = {
    initial: {
      background: "transparent",
      width: "0%",
    },
    hover: {
      background: "linear-gradient(to right, #007bff 0%, #c600ff 100%)", // Change this to your desired color
      width: "90%",
      transition: {
        background: { duration: 1 },
        width: { duration: 0.7 },
      },
    },
  };
  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <title></title>
        <meta name="description" content="" />
        <meta name="keywords" content="" />
      </Helmet>
      <div>
        {/* <TopBanner name="About" /> */}
        <TopBanner2 name="About" />
        <div className={styles.HeroSection}>
          <div className={styles.body}>
            <motion.div
              className={styles.LeftSection}
              initial={{ opacity: 0, x: -75 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              transition={{
                ease: "linear",
                type: "spring",
                duration: 5,
                bounce: 0.4,
              }}
            >
              <div>
                <img src={image12} alt="imag1" />
              </div>
              <div className={styles.experienceDiv}>
                <span>6+</span>
                <p> Years Experience</p>
              </div>
              <div className={styles.CurveDiv}>
                <img src={curves} alt="imag1" />
              </div>
              {/* <div className={styles.LineDiv}>
                            <img src={Line} alt="imag1" />
                        </div> */}
            </motion.div>
            <div className={styles.RightSection}>
              <ScrollAnimation>
                <h4>
                  We are consumed by a burning desire to develop, refine, and
                  perfect ourselves.
                </h4>
              </ScrollAnimation>
              <ScrollAnimation>
                <h1>ABOUT MURPHYS</h1>
              </ScrollAnimation>
              <ScrollAnimation>
                <h1> TECHNOLOGY</h1>
              </ScrollAnimation>

              <p>
                Our passion has been developing unique, highly functional,
                visually appealing websites and associated marketing materials
                for over six years. We are proud of our reputation as a
                seasoned, innovative web design firm in Sydney, with a track
                record of successfully establishing and maintaining long-term
                partnerships with each of our customers. Our experienced,
                devoted, and talented personnel will provide you with
                personalized, timely, and attentive customer service, as well as
                a unique and welcoming blend of professionalism and
                friendliness. Whether we're creating a new website for you or
                assisting with the maintenance of an existing one, you'll always
                be our top focus.
              </p>
              {/* <div className={styles.gridContainer}>
                            <div className={styles.gridItem}>
                                <div className={styles.icons}>
                                    <img src={Customer} alt="creative" />
                                </div>
                                <div className={styles.text}>
                                    <p>Best Customer Support</p>
                                </div>
                            </div>
                            <div className={styles.gridItem}>
                                <div className={styles.icons}>
                                    <img src={Creative} alt="creative" />
                                </div>
                                <div className={styles.text}>
                                    <p>Creative Designers</p>
                                </div>
                            </div>
                            <div className={styles.gridItem}>
                                <div className={styles.icons}>
                                    <img src={Services} alt="creative" />
                                </div>
                                <div className={styles.text}>
                                    <p>Great Services</p>
                                </div>
                            </div>
                            <div className={styles.gridItem}>
                                <div className={styles.icons}>
                                    <img src={Team} alt="creative" />
                                </div>
                                <div className={styles.text}>
                                    <p>Professional Team</p>
                                </div>
                            </div>
                        </div> */}
              <ScrollAnimation>
                <button
                  className={styles.btn}
                  id={styles.btn1}
                  onClick={() => navigate("/contact")}
                >
                  Rock & Roll
                </button>
              </ScrollAnimation>
            </div>
          </div>
        </div>
        <div className={styles.VisionDiv}>
          <ScrollAnimation>
            <h4>Our goal is client's success and their business growth</h4>
          </ScrollAnimation>
          <ScrollAnimation>
            <h1>OUR VISION</h1>
          </ScrollAnimation>

          <div className={styles.innerDiv}>
            <div className={styles.LeftSection}>
              <div className={styles.items}>
                <div className={styles.icon}>
                  <img src={Vision} alt="visison" />
                </div>
                <div className={styles.text}>
                  <p>
                    To Become A Global Partner In Software and Web Application
                    Outsourcing For Medium To Large Scale Companies
                  </p>
                </div>
              </div>
              <motion.hr
                initial="initial"
                whileInView="hover"
                variants={hrVariants}
              />
              <div className={styles.items}>
                <div className={styles.icon}>
                  <img src={Vision} alt="visison" />
                </div>
                <div className={styles.text}>
                  <p>
                    To provide best innovation, enhancing our customers’
                    competitiveness
                  </p>
                </div>
              </div>
              <motion.hr
                initial="initial"
                whileInView="hover"
                variants={hrVariants}
              />
              <div className={styles.items}>
                <div className={styles.icon}>
                  <img src={Vision} alt="visison" />
                </div>
                <div className={styles.text}>
                  <p>
                    To provide best solutions coupled with best practices for
                    customer satisfaction in very cheap price without
                    compromising in quality
                  </p>
                </div>
              </div>
              <motion.hr
                initial="initial"
                whileInView="hover"
                variants={hrVariants}
              />
            </div>
            <div className={styles.RightSection}>
              <ImageAnimation>
                <img src={image2} alt="hihi" />
              </ImageAnimation>
            </div>
          </div>
          <div className={styles.MissionDiv}>
            <motion.div className={styles.LeftSection}>
              <ImageAnimation>
                <div className={styles.image}>
                  <img src={image3} alt="" />
                </div>
              </ImageAnimation>
            </motion.div>
            <div className={styles.RightSection}>
              {/* <h4>To leverage our strong skills and extensive experience in developing and managing secure, scalable and highly innovative solutions in software and web technology.
                        </h4> */}
              <ScrollAnimation>
                <h4>Our goal is client's success and their business growth</h4>
              </ScrollAnimation>
              <ScrollAnimation>
                <h1>OUR MISSION</h1>
              </ScrollAnimation>
              <p className={styles.curvedpara}>
                To leverage our strong skills and extensive experience in
                developing and managing secure, scalable and highly innovative
                solutions in software and web technology.
              </p>
              <p>
                Our mission is to assist our clients in presenting a
                professional front to the marketplace. Presentation is
                everything and we believe this at UI Direct.This is why we offer
                the array of services that we do. Our clients need to showcase
                themselves in a professional manner - and this is why we offer
                web design, graphic design, Internet marketing, social media
                management, and much more.{" "}
              </p>
              <p>
                Today, consumers are everywhere. They look at websites, they
                look at Facebook and twitter, they look at letterhead, and they
                look at everything else in order to determine whether a company
                is "worthy" of doing business with them. If you fail to provide
                a professional image in any one of these areas, you may fail to
                obtain the level of business and thus the level of success that
                needs to be achieved. Our mission is to help with your
                professionalism through the services that we offer.
              </p>
            </div>
          </div>
        </div>

        {/* <div className={styles.outerDiv}>
                <div className={styles.leftdiv}>
                    <h4>GETTING A QUICK QUOTE HAS NEVER BEEN EASIER.</h4>
                    <p>Simply speak with our representative (02 7254 4827) or contact us.</p>
                </div>
                <div className={styles.rightdiv}>
                    <button onClick={() => navigate('/contact')}>Request a Quote</button>
                </div>
            </div> */}
        <GetAQuote />
      </div>
    </>
  );
};

export default About;
