import React from 'react'
import styles from './MobileApp.module.css'
import Backgroundimage from '../../img/Background/apps3.webp'
import GooglePlay from '../../img/Flaticon/googleplay.webp'
import apple from '../../img/Flaticon/applelogo.webp'
import Phone from '../../img/Home/mockup2.webp'
import ScrollAnimation from '../Animation/ScrollAnimation'
import { motion } from 'framer-motion'

const MobileApp = () => {
    return (
        <div className={styles.outerDiv}>
            <img src={Backgroundimage} alt="background" />
            <div className={styles.RightSection}>
                <img src={Phone} alt="image1" />
                <div className={styles.ButtonDivs}>
                    <a href="/">
                        <div className={styles.Button}>
                            <img src={GooglePlay} alt="google" />
                            <div>
                                <h5>Andriod App on</h5>
                                <p>Google Play</p>
                            </div>
                        </div>
                    </a>
                    <a href="/">
                        <div className={styles.Button}>
                            <img src={apple} alt="google" />
                            <div>
                                <h5>Available on the</h5>
                                <p>App Store</p>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
            <div className={styles.LeftSection}>
                <ScrollAnimation>
                    <h4>DOWNLOAD OUR OFFICIAL MOBILE APPLICATION</h4>
                </ScrollAnimation>
                <ScrollAnimation>
                    <h1>
                        Murphy's App for your Smartphone
                    </h1>
                </ScrollAnimation>
                <motion.p
                    initial={{ opacity: 0, y: 75 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 1 }}
                    viewport={{ once: true }}
                >
                    Congratulations; you're here because you want to expand your company. Create a brand. Make something incredible. As a digital marketing company, we support business owners in realizing their goals, and we look forwards to learning about yours.
                </motion.p>
                <div className={styles.ButtonDivs}>
                    <a href="/">
                        <div className={styles.Button}>
                            <img src={GooglePlay} alt="google" />
                            <div>
                                <h5>Andriod App on</h5>
                                <p>Google Play</p>
                            </div>
                        </div>
                    </a>
                    <a href="/">
                        <div className={styles.Button}>
                            <img src={apple} alt="google" />
                            <div>
                                <h5>Available on the</h5>
                                <p>App Store</p>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    )
}

export default MobileApp
